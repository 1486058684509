import { networks, address as BitcoinAddres, Transaction, Psbt } from 'bitcoinjs-lib';
import crypto from 'crypto-js'
import { LOCAL_BTC_ADDRESS_KEY } from '@/contains'
import { ConnectWalletType, connectWalletTypeAtom, jotaiStore, showWalletConnectDialogAtom } from '@/store'
import { addressAtom } from '@/store/user'
import { message } from 'antd';
// import { OKXBtcProvider } from "@okxconnect/universal-provider";

export const connectUnisatWallet = async () => {
    try {
        const accounts = await window.unisat.requestAccounts();

        localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, accounts[0]);
        jotaiStore.set(addressAtom, accounts[0])
    } catch (error) {
        message.error('Error connecting to wallet')
    }
}

export const connectBitgetWallet = async () => {
    try {
        const provider = window.bitkeep.unisat
        const accounts = await provider.requestAccounts();

        localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, accounts[0]);
        jotaiStore.set(addressAtom, accounts[0])
    } catch (error) {
        message.error('Error connecting to wallet')
        console.error("Error connecting to wallet:", error);
    }
}

export const connectOkxWallet = async () => {
    try {
        const rs = await window?.okxwallet.bitcoin.connect()
        const { address } = rs

        localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, address);
        jotaiStore.set(addressAtom, address)
    } catch (error) {
        message.error('Error connecting to wallet')
        console.error("Error connecting to wallet:", error);
    }
}

// export const connectOkxWalletForApp = async () => {
//     const okxUniversalProvider = jotaiStore.get(okxWalletProviderAtom)

//     try {
//         await okxUniversalProvider.connect({
//             namespaces: {
//                 btc: {
//                     chains: ["btc:mainnet"],
//                 }
//             },
//             sessionConfig: {
//                 redirect: "back"
//             }
//         })

//         const okxBtcProvider = new OKXBtcProvider(okxUniversalProvider)
//         const account = okxBtcProvider.getAccount("btc:mainnet")
//         const address = account?.address as string

//         message.info(`address-->${address}`)

//         jotaiStore.set(okxWalletBtcProvderAtom, okxBtcProvider)
//         localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, address);
//         jotaiStore.set(addressAtom, address)
//     } catch (error: any) {
//         message.error(error.message)
//         console.error("Error connecting to wallet:", error);
//     }
// }

export const checkWalletConnection = async () => {
    const type = jotaiStore.get(connectWalletTypeAtom)

    if (window.unisat && type === ConnectWalletType.UNISAT) {
        try {
            const accounts = await window.unisat.getAccounts();
            if (accounts.length > 0) {

                jotaiStore.set(addressAtom, accounts[0])
                return accounts[0]
            } else {
                console.log("Wallet is not connected");
                return null;
            }
        } catch (error) {
            console.error("Error checking wallet connection:", error);
            return null;
        }
    } else if (window.bitkeep?.unisat && type === ConnectWalletType.BITGET) {
        try {
            const provider = window.bitkeep.unisat
            const accounts = await provider.requestAccounts();
            if (accounts.length > 0) {

                jotaiStore.set(addressAtom, accounts[0])
                return accounts[0]
            } else {
                console.log("Wallet is not connected");
                return null;
            }
        } catch (error) {
            console.error("Error checking wallet connection:", error);
            return null;
        }
    } else if (type === ConnectWalletType.OKX) {
        const rs = await window?.okxwallet.bitcoin.connect()
        const { address } = rs

        if (address) {
            jotaiStore.set(addressAtom, address)
            return address
        } else {
            console.log("Wallet is not connected");
            return null;
        }
    } else {
        console.log("Unisat Wallet not found");
        return null;
    }
}


export const disconnectWallet = async () => {
    const type = jotaiStore.get(connectWalletTypeAtom)

    jotaiStore.set(addressAtom, '')
    localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
    if (type === ConnectWalletType.UNISAT) {
        await window?.unisat?.disconnect?.()
    } else if (type === ConnectWalletType.BITGET) {
        await window?.bitkeep?.unisat?.disconnect?.()
    } else if (type === ConnectWalletType.OKX) {
        await window?.okxwallet?.disconnect?.()
    }
}


export const getStoredWalletAddress = () => {
    const address = localStorage.getItem(LOCAL_BTC_ADDRESS_KEY);
    if (address) {
        // console.log("Restoring wallet address from storage:", address);
        jotaiStore.set(addressAtom, address)

        return address
    } else {
        return null;
    }
}

export const restoreConnection = async () => {
    const storedAddress = getStoredWalletAddress();
    const type = window.localStorage.getItem('connectWalletType') as ConnectWalletType

    if (storedAddress && type) {
        const currentAddress = await checkWalletConnection();

        if (currentAddress !== storedAddress) {
            connectByWallet(type)
        } else {
            jotaiStore.set(addressAtom, storedAddress)
        }
    } else {
        console.log("No previous wallet connection found");
    }
}

export const getAddressPubKey = async (address?: string) => {
    try {
        const type = jotaiStore.get(connectWalletTypeAtom)
        if (type === ConnectWalletType.UNISAT) {
            const pubkey: string = await window.unisat.getPublicKey(address)
            return pubkey
        } else if (type === ConnectWalletType.BITGET) {
            const pubkey = await window?.bitkeep?.unisat?.getPublicKey();
            return pubkey
        } else if (type === ConnectWalletType.OKX) {
            const pubkey = await window?.okxwallet?.bitcoin?.getPublicKey();
            return pubkey
        }

        return ''
    } catch (e) {
        console.log(e)

        return ''
    }
}

export const toBase64 = (address: string) => crypto.SHA256(address).toString(crypto.enc.Base64)
export const encryptionAddress = (address?: string, start = 6, end = 4) => {
    if (!address) return ''
    return `${address.slice(0, Math.max(0, start))}...${address.slice(-end)}`
}

export const getUtxoInfo = async (userAddress: string) => {

    const response = await fetch(`https://blockstream.info/api/address/${userAddress}/utxo`);
    const data = (await response.json()) ?? [];

    const utxo = data?.[data.length - 1] ?? {}
    const res = await fetch(`https://blockstream.info/api/tx/${utxo?.txid}/outspend/${utxo?.vout}`)
    const rs = await res.json()

    if (!rs?.spent) {
        const outputResponse = await fetch(`https://blockstream.info/api/tx/${utxo?.txid}`);
        const outputData = await outputResponse.json();
        const output = outputData.vout[utxo?.vout];

        return output
    }

    return {}
}

export const getUtxos = async (userAddress: string) => {
    const response = await fetch(`https://blockstream.info/api/address/${userAddress}/utxo`);
    const data = (await response.json()) ?? [];

    const utxos = await Promise.all(data?.map(async (utxo: any) => {
        const res = await fetch(`https://blockstream.info/api/tx/${utxo?.txid}/outspend/${utxo?.vout}`)
        const rs = await res.json()

        if (!rs?.spent) {
            const outputResponse = await fetch(`https://blockstream.info/api/tx/${utxo?.txid}`);
            const outputData = await outputResponse.json();
            const output = outputData.vout[utxo?.vout];

            return { ...utxo, ...output }
        }

        return {}
    }))

    return utxos
}

export const hexToPSBTForUtxos = (hexString: string, inputs: any = [], fees?: {
    btc_change: number,
    btc_in: number,
    btc_out: number
}) => {
    fees = fees ?? { btc_change: 0, btc_in: 0, btc_out: 0 }

    const psbt = new Psbt({ network: networks.bitcoin });
    try {
        const tx = Transaction.fromHex(hexString);

        inputs.forEach((input: any) => {
            const txidBuffer = Buffer.from(input.txid, 'hex').reverse()
            const _input = {
                hash: txidBuffer,
                index: input.vout,
                witnessUtxo: {
                    script: Buffer.from(input.scriptPubKey ?? input.scriptpubkey, 'hex'),
                    value: input.value,
                },
            }

            psbt.addInput(_input);
        })


        for (const output of tx.outs) {
            psbt.addOutput({
                script: output.script,
                value: output.value,
            });
        }

        // 生成 PSBT 交易 hex
        if (isInOkxWallet()) {
            return psbt.toHex()
        }

        return psbt.toBase64()
    } catch (e: any) {
        message.error(e.message || e)
        console.log(e)
    }
}

export const hexToPSBT = (hexString: string, address: string, utxo: any, fee?: number) => {
    try {
        const psbt = new Psbt({ network: networks.bitcoin });

        const tx = Transaction.fromHex(hexString);

        for (let i = 0; i < tx.ins.length; i++) {
            const input = tx.ins[i];
            const { prefix } = BitcoinAddres.fromBech32(address)
            const isSegwit = ['bc', 'tb'].includes(prefix)

            const _input: any = {
                hash: input.hash,
                index: input.index,
                sequence: input.sequence,
            }

            if (isSegwit) {
                _input['witnessUtxo'] = {
                    script: Buffer.from(utxo.scriptpubkey, 'hex'),
                    value: utxo.value
                }
            }

            psbt.addInput(_input);
        }

        for (const output of tx.outs) {
            psbt.addOutput({
                script: output.script,
                value: output.value,
            });
        }

        // 生成 PSBT 交易 hex
        const psbtHex = psbt.toBase64();

        return psbtHex
    } catch (e: any) {
        console.log('error: hexToPSBT2 -> ', e.message)
        return ''
    }
}

export const signMessage = async (hex: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const type = jotaiStore.get(connectWalletTypeAtom)

            if (window.unisat && type === ConnectWalletType.UNISAT) {
                const signedString = await window.unisat.signPsbt(hex)

                resolve(signedString)
            } else if (window.bitkeep?.unisat && type === ConnectWalletType.BITGET) {
                const signedString = await window.bitkeep.unisat.signPsbt(hex)
                resolve(signedString)
            } else if (type === ConnectWalletType.OKX) {
                const signedString = await window.okxwallet?.bitcoin?.signPsbt(hex)

                resolve(signedString)
            }
        } catch (e: any) {
            console.log('error: signMessage -> ', e.message)
            reject(e.message)
        }
    })
}

export const broadcastTx = async (signedTxString: string) => {
    return new Promise(async (resolve, reject) => {
        const type = jotaiStore.get(connectWalletTypeAtom)
        try {
            if (window.unisat && type === ConnectWalletType.UNISAT) {
                const rs = await window?.unisat?.pushPsbt(signedTxString)
                resolve(rs)
            } else if (window.bitkeep?.unisat && type === ConnectWalletType.BITGET) {
                const rs = await window.bitkeep.unisat.pushPsbt(signedTxString)

                resolve(rs)
            } else if (type === ConnectWalletType.OKX) {
                const signedString = await window.okxwallet?.bitcoin?.pushPsbt(signedTxString)
                resolve(signedString)
            }
        } catch (e: any) {
            message.error(`broadcastTx--->${e.message}`)
            console.log('error: broadcastTx -> ', e.message)
            reject(e.message)
        }
    })
}

export const getBitcoinFees = async () => {
    const url = 'https://mempool.space/api/v1/fees/recommended';

    try {
        const response = await fetch(url);
        const data = await response.json();
        const rs: any = {
            1: data?.fastestFee ?? 0,
            2: data?.fastestFee ?? 0,
            3: data?.fastestFee ?? 0,
            4: data?.fastestFee ?? 0,
            5: data?.halfHourFee ?? 0,
            6: data?.halfHourFee ?? 0,
            7: data?.halfHourFee ?? 0,
            8: data?.hourFee ?? 0,
            9: data?.hourFee ?? 0,
            10: data?.hourFee ?? 0
        }

        return rs
    } catch (error) {
        console.error("Error fetching Bitcoin fees:", error);
        return {}
    }
}

export const randomName = () => {
    const firstDigit = Math.floor(Math.random() * 9) + 1; // 确保第一位不是0
    let digits = firstDigit.toString();

    for (let i = 0; i < 18; i++) {
        digits += Math.floor(Math.random() * 10); // 生成其余的4位数字
    }

    const name = 'A' + digits;

    return name
}

export const connectByWallet = async (type: ConnectWalletType) => {
    jotaiStore.set(connectWalletTypeAtom, type)

    if (type === ConnectWalletType.UNISAT) {
        if (!window?.unisat) {
            message.error('Unisat Wallet not found')
        } else {
            window.localStorage.setItem('connectWalletType', type)
            connectUnisatWallet()
        }
    } else if (type === ConnectWalletType.BITGET) {
        if (!window?.bitkeep?.unisat) {
            message.error('Bitget Wallet not found')
        } else {
            window.localStorage.setItem('connectWalletType', type)
            connectBitgetWallet()
        }
    } else if (type === ConnectWalletType.OKX) {
        connectOkxWallet()
        window.localStorage.setItem('connectWalletType', type)
    }

    jotaiStore.set(showWalletConnectDialogAtom, false)
}

export const isInOkxWallet = () => {
    const userAgent = navigator.userAgent;

    return userAgent.includes("OKEx")
}

export const storeTipsBtc = (address: string) => {
    const tipsAddressListStr = window.localStorage.getItem('hasTipsAddress') ?? '[]'
    const tipsAddressList = JSON.parse(tipsAddressListStr) ?? []

    if(tipsAddressList.indexOf(address) > -1) {
        return true
    }

    tipsAddressList.push(address)
    window.localStorage.setItem('hasTipsAddress', JSON.stringify(tipsAddressList))

    return false
}