import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import { useEffect, useMemo, useState } from 'react'
import { TableFooter } from '@/components/TableFooter'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { addressAtom } from '@/store'
import { getOrderMatchesApi } from '@/request/counterparty'
import { formatDecimals } from '@/utils/format-decimals'
import { cn } from '@/utils/cn'
import { Button, message, Modal } from 'antd'
import { useRequest } from 'ahooks'
import { payBTC } from '@/request/api'
import { broadcastTx, getUtxos, hexToPSBTForUtxos, signMessage } from '@/utils'
import { useLocation } from 'react-router-dom'
import tips2Img from '@/assets/images/global/2.png'
import tips3Img from '@/assets/images/global/3.png'

export const OrderHistory = () => {
  const userAddress = useAtomValue(addressAtom)
  const [orderInfo, setOrderInfo] = useState<any>(null)
  const [showBtcTips, setShowBtcTips] = useState<number>(0)
  const { search } = useLocation()

  const addressList = [userAddress as string]
  const { data = [], refetch } = useQuery({
    refetchOnMount: 'always',
    queryKey: [{ key: 'getOrderMatchesApi', addressList }],
    queryFn: async () => {
      const rs = await getOrderMatchesApi(addressList)
      return rs || []
    },
  })

  useEffect(() => {
    const paramStr = search?.split('?')?.[1]
    if (paramStr && paramStr.indexOf('tips') > -1) {
      const params = search.split('&') ?? []
      const str = params.find((str) => str.indexOf('tips') > -1) ?? ''
      const rs = str.split('=')[1]

      if (rs === 'true') {
        setShowBtcTips(1)
      }
    }
  }, [search])

  const { loading, run } = useRequest(async (id: string) => {
    const hex: any = await payBTC(userAddress, id)
    const utxos = await getUtxos(userAddress)
    const psbtHex = hexToPSBTForUtxos(hex, utxos) as string
    const signedPsbt = await signMessage(psbtHex) as string
    await broadcastTx(signedPsbt)

    message.success('Transaction Submitted! Please kindly wait for the block confirmation!')
    setOrderInfo(null)
    refetch()
  }, {
    manual: true,
  })

  const formatedOrderMatches = useMemo(() => {
    const formatedData = data.map((item) => {
      const formatedItem: Record<string, any> = {
        block_index: item.block_index,
        status: item.status,
      }

      if (userAddress === item.tx0_address) {
        formatedItem.address_label = userAddress
        formatedItem.give_quantity = item.forward_quantity
        formatedItem.get_quantity = item.backward_quantity
        formatedItem.give_asset = formatUnit(item.forward_asset)
        formatedItem.get_asset = formatUnit(item.backward_asset)
      } else {
        formatedItem.address_label = userAddress
        formatedItem.give_quantity = item.backward_quantity
        formatedItem.get_quantity = item.forward_quantity
        formatedItem.give_asset = formatUnit(item.backward_asset)
        formatedItem.get_asset = formatUnit(item.forward_asset)
      }

      formatedItem.give_quantity_str = formatDecimals(formatedItem.give_quantity, {
        unit: formatedItem.give_asset,
        decimals: 8,
        volScale: 8,
      })

      formatedItem.get_quantity_str = formatDecimals(formatedItem.get_quantity, {
        unit: formatedItem.get_asset,
        decimals: 8,
        volScale: 8,
      })
      formatedItem.info = { ...item }

      return formatedItem
    })

    return formatedData
  }, [data, userAddress])

  return (
    <div className={cn(styles.wrap, 'py-9 px-2.5 sm:px-9')}>
      <div className={styles.titles}>
        <p>My Order History</p>
      </div>
      <div className={cn(styles.body, '!px-2.5 sm:!px-5')}>
        <div className={cn(styles.tableWrap, 'overflow-x-auto')}>
          <Table
            columns={[
              { dataIndex: 'status', label: 'status' },
              { dataIndex: 'block_index', label: 'block' },
              { dataIndex: 'address_label', label: 'address' },
              { dataIndex: 'give_quantity_str', label: 'sell Quantity' },
              { dataIndex: 'get_quantity_str', label: 'Buy Quantity' },
              {
                dataIndex: 'action', label: 'Action', align: 'right',
                render: (order) => {
                  if (order.status === 'pending' && order.give_asset === 'BTC') {
                    return (
                      <Button className="cursor-pointer" type='text' onClick={() => setOrderInfo(order)}>
                        Pay BTC
                      </Button>
                    )
                  }
                  return <></>
                },
              }
            ]}
            data={formatedOrderMatches}
            noData={<TableFooter />}
          />
        </div>
      </div>
      <Modal title={'Order matched successfully！Please pay btc.'} open={!!orderInfo?.address_label} className='w-[400px]' onCancel={() => setOrderInfo(null)} footer={null}>
        <p className='text-[14px] mt-[20px]'>Send to</p>
        <p className='text-[14px] mt-[5px] text-[gray]'>{orderInfo?.info?.tx1_address}</p>
        <p className='text-[14px] mt-[20px]'>Amount</p>
        <p className='text-[14px] mt-[5px] text-[gray]'>{orderInfo?.give_quantity_str}</p>
        <div className='flex justify-end gap-[10px]'>
          <Button className={'bg-[transparent]'} onClick={() => setOrderInfo(null)}>
            Cancel
          </Button>
          <Button loading={loading} type='primary' onClick={() => run(orderInfo?.info?.id)}>
            Pay BTC
          </Button>
        </div>
      </Modal>
      {
        !!(showBtcTips && showBtcTips === 1) && <div className='fixed w-[100vw] h-[100vh] bg-[rgba(0,0,0,1)] top-[0px] left-[0px] zIndex-[10000000]'>
          <div className='absolute w-[90%] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-between	items-center px-[10px] py-[10px] overflow-hidden'>
            <img src={tips2Img} alt="" className='w-[100%] h-[auto]' />
            <div className='flex items-end w-[100%] mt-[20px]'>
              <div className='w-[100%]'>
                <div className='w-[100%] flex items-center justify-between'>
                  <p className='text-[20px] font-[600]'>Tutorial for Beginners</p>
                  <p className='border-[1px] border-[#353A47] rounded-[26px] py-[2px] px-[12px]'>2/3</p>
                </div>
                <div>
                  <p className='mt-[20px] text-[12px]'>In order to purchase tokens with BTC, you will need to  pay BTC manually after the order has been successfully matched. The specific steps of the operation are as follows:</p>
                  <p className='text-[#00FF8E] text-[12px] mt-[30px]'>Step 2. After the order is successfully matched, check My Order History, find the order you want to pay and click the pay button.</p>
                </div>
                <div className='flex justify-end mt-[20px]'>
                  <p className='border-[white] cursor-pointer rounded-[37px] px-[36px] py-[12px] border-[1px]' onClick={() => setShowBtcTips(2)}>Next</p>
                  <p className='border-[white] cursor-pointer rounded-[37px] px-[36px] py-[12px] border-[1px] ml-[20px]' onClick={() => setShowBtcTips(0)}>Skip</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {
        !!(showBtcTips && showBtcTips === 2) && <div className='fixed w-[100vw] h-[100vh] bg-[rgba(0,0,0,1)] top-[0px] left-[0px] zIndex-[10000000]'>
          <div className='absolute w-[90%] max-w-[600px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-between	items-center px-[10px] py-[10px] overflow-hidden'>
            <img src={tips3Img} alt="" className='w-[100%] h-[auto]' />
            <div className='flex items-end w-[100%] mt-[20px] flex-wrap'>
              <div className='w-[100%]'>
              <div className='w-[100%] flex items-center justify-between'>
                  <p className='text-[20px] font-[600]'>Tutorial for Beginners</p>
                  <p className='border-[1px] border-[#353A47] rounded-[26px] py-[2px] px-[12px]'>3/3</p>
                </div>
                <div>
                  <p className='mt-[20px] text-[12px]'>In order to purchase tokens with BTC, you will need to  pay BTC manually after the order has been successfully matched. The specific steps of the operation are as follows:</p>
                  <p className='text-[#00FF8E] text-[12px] mt-[30px]'>Step 3. Click the Pay button, then shows a confirmation pop-up, click Pay and then confirm in your wallet to complete the transaction.</p>
                </div>
              </div>
              <div className='flex justify-end mt-[20px] flex-1'>
                <p className='bg-[#00FF8E] w-[102px] text-[black] cursor-pointer rounded-[37px] px-[36px] py-[12px] ml-[20px]' onClick={() => setShowBtcTips(0)}>OK</p>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

function formatUnit(unit: string) {
  if (unit === 'XCP') {
    return 'tBRT'
  }

  return unit
}
