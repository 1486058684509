import { Header } from './Header'
import styles from './index.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Side, SideItems } from './Side'
import { useAtom, useAtomValue } from 'jotai'
import { addressAtom, ConnectWalletType, connectWalletTypeAtom, jotaiStore, showMobileMenuAtom, showWalletConnectDialogAtom } from '@/store'
import { useEffect } from 'react'
import { connectByWallet, restoreConnection } from '@/utils'
import { LOCAL_BTC_ADDRESS_KEY } from '@/contains'
import Menu from 'assets/images/header/Menu.svg'
import { useFeeRate } from '@/hooks/useFees'
import { message } from 'antd'
import { Dialog } from '@/components/ui/NewDialog'
import unisatIcon from '@/assets/images/icons/unisat.png'
// import bitgetIcon from '@/assets/images/icons/bitget.png'
import okxIcon from '@/assets/images/icons/icon-okx.svg'
import { useConnectOkxWallet } from '@/hooks/useOkxConnect'

export const Layout = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const address = useAtomValue(addressAtom)
  const [showMobileMenu, setShowMobileMenu] = useAtom(showMobileMenuAtom)
  const [showWalletConnectDialog, setShowWalletConnectDialog] = useAtom(showWalletConnectDialogAtom)
  const connectWalletType = useAtomValue(connectWalletTypeAtom)
  const { handleInitOkxConnector } = useConnectOkxWallet()
  useFeeRate()

  // @ts-ignore
  const [_, contextHolder] = message.useMessage();

  useEffect(() => {
    if (address) {
      if (pathname === '/') navigate('/balance')
    } else {
      navigate('/')
    }
  }, [address, pathname, navigate])

  useEffect(() => {
    if (window.localStorage.getItem('connectWalletType') === ConnectWalletType.UNISAT && window.unisat) {
      window.unisat.on('accountsChanged', (accounts: any[]) => {
        if (accounts.length > 0) {
          localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, accounts[0]);
          window.location.reload()
        } else {
          localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
        }
      });

      window.unisat.on('disconnect', () => {
        jotaiStore.set(addressAtom, '')
        localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
      });
    }

    if (window.localStorage.getItem('connectWalletType') === ConnectWalletType.BITGET && window.bitkeep?.unisat) {
      window.bitkeep?.unisat?.on('accountsChanged', (accounts: any[]) => {
        if (accounts.length > 0) {
          localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, accounts[0]);
          window.location.reload()
        } else {
          localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
        }
      });

      window.bitkeep?.unisat?.on('disconnect', () => {
        jotaiStore.set(addressAtom, '')
        localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
      });
    }

    if (window.localStorage.getItem('connectWalletType') === ConnectWalletType.OKX && window.okxwallet) {
      window.okxwallet?.bitcoin?.on('accountsChanged', (accounts: any[]) => {
        if (accounts.length > 0) {
          localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, accounts[0]);
          window.location.reload()
        } else {
          localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
        }
      });

      window.okxwallet?.bitcoin?.on('disconnect', () => {
        jotaiStore.set(addressAtom, '')
        localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
      });
    }

    setTimeout(() => restoreConnection(), 200)
  }, [connectWalletType])

  useEffect(() => {
    handleInitOkxConnector()
  }, [handleInitOkxConnector])

  return (
    <div className={styles.layout}>
      {contextHolder}
      {
        showMobileMenu && <div className={styles.menuPage}>
          <div className={styles.menuPage_header}>
            <img src={Menu} alt="" onClick={() => setShowMobileMenu(false)} />
          </div>
          <div className={styles.menuPage_container}>
            {
              SideItems?.map((sideItem) => {
                return (
                  <div className={[styles.menuPage_container_item, pathname.startsWith(sideItem.path) ? styles.currentItem : ''].join(' ')} key={sideItem.path} onClick={() => {
                    setShowMobileMenu(false)
                    navigate(sideItem.path)
                  }}>
                    <span>{sideItem.title}</span>
                  </div>

                )
              })
            }
          </div>
          <div className={styles.menuPage_container_item} onClick={() => {
            window.open('https://bitroot-interface.vercel.app/faucet', '__blank')
          }}>
            Faucet
          </div>
        </div>
      }
      <Header />
      <div className={styles.container}>
        {pathname !== '/' && <Side />}
        <div className={styles.container_body}>
          <Outlet />
        </div>
      </div>
      <Dialog
        open={showWalletConnectDialog}
        title='Connect Wallet'
        closable={true}
        onClose={() => setShowWalletConnectDialog(false)}
        content={<div>
          <div className='py-[13px] px-[24px] flex items-center bg-[#191D24] rounded-[8px] mt-[13px] cursor-pointer' onClick={() => connectByWallet(ConnectWalletType.UNISAT)}>
            <div className='rounded-[8px] w-[36px] h-[36px] bg-[white] flex items-center justify-center'>
              <img src={unisatIcon} alt='unisat' />
            </div>
            <p className='ml-[16px]'>Unisat Wallet</p>
          </div>
          <div className='mt-[2px] py-[13px] px-[24px] flex items-center bg-[#191D24] rounded-[8px] cursor-pointer' onClick={() => connectByWallet(ConnectWalletType.OKX)}>
            <div className='rounded-[8px] w-[36px] h-[36px] bg-[white] flex items-center justify-center'>
              <img src={okxIcon} alt="okx" />
            </div>
            <p className='ml-[16px]'>OKX Wallet</p>
          </div>
        </div>}
      />
    </div >
  )
}
