import { NiceModal } from '@/components/dialog'
import { Modal, ModalContent, ModalHeader, ModalTitle, ModalFooter, ModalAction } from '@/components/ui-shadcn/modal'
import { useModal } from '@ebay/nice-modal-react'
import { Input } from 'antd'
import { AssetHistoryType, AssetInfo, fetchAssetHistoryApi } from '@/request/asset'
import { formatDecimals } from '@/utils/format-decimals'
import { Checkbox } from '@/components/ui-shadcn/checkbox'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { TokenItemType } from './token-item'

export const AssetsInfoModal = NiceModal.create(({ token, assetInfo }: { token: TokenItemType; assetInfo: AssetInfo }) => {
  const modal = useModal()

  return (
    <Modal open={modal.visible} onOpenChange={modal.hide}>
      <ModalContent aria-describedby={undefined} className="max-w-[90vw] max-h-[80vh] overflow-hidden overflow-y-auto">
        <ModalHeader className='oveflow-hidden max-w-[100%]'>
          <ModalTitle className='text-[16px] w-[100%]'>Info for {assetInfo.asset} ( Read Only )</ModalTitle>
        </ModalHeader>
        <div className="flex flex-col gap-[30px] text-foreground text-[16px]">
          <div>
            <div >Owned By</div>
            <div className='mt-[10px]'>
              <Input className='max-w-[350px]' value={assetInfo.owner} disabled={true} />
            </div>
          </div>
          <div>
            <div >Description</div>
            <div className='mt-[10px]'>
              <Input className='max-w-[350px]'  value={assetInfo.description} disabled={true} />
            </div>
          </div>
            <div>
            <div>Total Issued</div>
            <div className='flex-1 flex flex-col gap-9'>
              <div className='mt-[10px]'>
                <Input className='max-w-[350px]'  value={formatDecimals(assetInfo.supply, {
                  decimals: assetInfo.divisible ? 8 : 0
                })} disabled={true} />
              </div>
              <div className='flex flex-col gap-3'>
                <div className='flex items-center gap-5'>
                  <Checkbox id="assets-info-locked" checked={assetInfo.locked} disabled />
                  <label className='text-[16px]' htmlFor='assets-info-locked'>Is Locked ？</label>
                </div>
                <div className='text-[#637592] text-[12px]'>Locked tokens may not have additional unit issued.
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='flex items-center gap-5'>
                  <Checkbox id="assets-info-divisible" checked={assetInfo.divisible} disabled />
                  <label className='text-[16px]' htmlFor='assets-info-divisible'>Is Divisible ？</label>
                </div>
                <div className='text-[#637592] text-[12px]'>Divisble assets can be subdivided into decimal places.</div>
              </div>
            </div>
          </div>

          <AssetHistory token={token} />
        </div>
        <ModalFooter>
          <ModalAction
            hide={modal.hide}
            cancel={false}
            confirm={{
              label: 'Close',
              cb: () => { }
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
})

function AssetHistory({ token }: { token: TokenItemType }) {
  const { data: assetHistory = [] } = useQuery({
    queryKey: [{ key: 'fetchAssetHistoryApi', asset: token.asset }],
    queryFn: async () => {
      const rs = await fetchAssetHistoryApi({
        asset: token.asset,
        reverse: true
      })

      return rs
    }
  })

  if (assetHistory.length === 0) return null

  return <div className='flex flex-col gap-3'>
    <div className='flex items-center gap-3'>
      <div className='flex w-full self-stretch gap-[30px] pb-3 border-b border-[#637592]'>
        <div className='w-[100px]'>Block Time</div>
        <div className='flex-1'>Description</div>
      </div>

    </div>

    {
      assetHistory.map((item, i) => {
        return <div key={`${item.type}_${i}`} className='flex items-center gap-3'>
          <div className='flex w-full self-stretch gap-[30px] pb-3 border-b border-[#637592]'>
            <div className='w-[100px]'>{
              dayjs(item.at_block_time).format('M/D/YY h:mm:ss A')}</div>
            <div className='flex-1 max-w-[200px]  break-words'>
              {
                item.type === AssetHistoryType.locked ?
                  <>Token locked</> :
                  item.type === AssetHistoryType.created ?
                    <>Token created with description '<b>{item.description ? item.description : undefined}</b>' and total issuance of <span className='text-[#FFAD36]'>{formatDecimals(item.total_issued_normalized)}</span> units. Owned by address <span className='text-primary'>{item.owner}</span></> :
                    item.type === AssetHistoryType.changed_description ?
                      <>Description changed from `{item.prev_description}` to `{item.new_description}`</> :
                      item.type === AssetHistoryType.transferred ?
                        <>
                          Token transferred from address <span className='text-primary'>{item.prev_owner}</span> to address <span className='text-primary'>{item.new_owner}</span>
                        </> :
                        item.type === AssetHistoryType.issued_more ?
                          <>
                            An additional <span className='text-[#FFAD36]'>{formatDecimals(item.additional_normalized)}</span> units issued. Total issuance increased to <span className='text-[#FFAD36]'>{formatDecimals(item.total_issued_normalized)}</span> units
                          </> :
                          <>Unknown operation: {(item as any).type}</>
              }
            </div>
          </div>
        </div>
      })
    }
  </div>
}
