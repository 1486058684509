import api from './index'

export const rpcRequest = async (method: string, params?: any) => {
  const rs = await api.post('/_t_api', {
    id: 0,
    jsonrpc: '2.0',
    method,
    params
  })

  return rs
}

export const isReady = async () => {
  const rs = await rpcRequest('is_ready')

  return rs
}

export const getPreferencesInfo = async (walletId: string) => {
  const rs: any = await rpcRequest('get_preferences', {
    for_login: true,
    network: 'mainnet',
    wallet_id: walletId
  })

  return rs
}

export const getChainAddressInfo = async (addresses: string[]) => {
  const rs = await rpcRequest('get_chain_address_info', {
    addresses,
    with_last_txn_hashes: 5,
    with_uxtos: true
  })

  return rs
}

export const storePreferences = async (wallet_id: string, preferences: any) => {
  const rs = await rpcRequest('store_preferences', {
    for_login: true,
    network: "mainnet",
    preferences,
    referer: "https://app.bitroot.co/",
    wallet_id
  })

  return rs
}

export const getLatestWalletMessages = async () => {
  const rs = await rpcRequest('get_latest_wallet_messages', { last_seq: 3890 })

  return rs
}


export const getNormalizedBalances = async (addresses: string[]) => {
  const rs = await rpcRequest('get_normalized_balances', { addresses })

  return rs
}

export type CreateTokenParamsType = {
  asset: string,
  description: string,
  divisible: boolean,
  quantity: number,
  source: string,
  pubkey: string,
  fee_per_kb: number,
}

export type CreateIssuanceRsData = {
  btc_change: number
  btc_fee: number
  btc_in: number
  btc_out: number
  tx_hex: string
}

export const createTokenAction = async (params: CreateTokenParamsType) => {
  const rs = await rpcRequest('proxy_to_counterpartyd', {
    method: 'create_issuance',
    params: {
      allow_unconfirmed_inputs: true,
      disable_utxo_locks: true,
      encoding: 'auto',
      extended_tx_info: true,
      transfer_destination: null,
      ...params
    }
  })

  return rs as unknown as CreateIssuanceRsData
}

export type CreateSendParamsType = {
  "source": string,
  "destination": string,
  "quantity": number,
  "asset": string,
  "pubkey": string[],
  fee_per_kb: number,
  segwit?: boolean,
}

export const createSend = async (params: CreateSendParamsType) => {
  const rs = await rpcRequest('proxy_to_counterpartyd', {
    method: 'create_send',
    params: {
      extended_tx_info: true,
      disable_utxo_locks: true,
      encoding: 'auto',
      allow_unconfirmed_inputs: true,
      ...params
    }
  })

  return rs
}

export const broadcastAction = async (hash: string) => {
  const rs = await rpcRequest('broadcast_tx', {
    signed_tx_hex: hash
  })

  return rs as unknown as number
}

export const getTopPairs = async (addresses: string[]) => {
  const rs: any = await rpcRequest('get_users_pairs', {
    max_pairs: 12,
    addresses
  })

  return rs
}

export const getMarketList = async () => {
  const rs: any = await rpcRequest('get_markets_list', {})

  return rs
}


export const getHistoryAction = async (address: string) => {
  const rs = await rpcRequest('get_raw_transactions', {
    limit: 2500,
    address
  })

  return rs
}

export const createArmoryUTx = async (txhex: string, pubkey: string) => {
  const rs = await rpcRequest('create_armory_utx', {
    unsigned_tx_hex: txhex,
    public_key_hex: pubkey
  })

  return rs
}

export const getMarketPriceHistory = async (asset1: string, asset2: string) => {
  const rs = await rpcRequest('get_market_price_history', {
    asset1: asset1 === 'tBRT' ? 'XCP' : asset1,
    asset2: asset2 === 'tBRT' ? 'XCP' : asset2,
  })

  return rs
}

export const getMarketTrades = async (asset1: string, asset2: string, addresses: string[]) => {
  const rs = await rpcRequest('get_market_trades', {
    asset1: asset1 === 'tBRT' ? 'XCP' : asset1,
    asset2: asset2 === 'tBRT' ? 'XCP' : asset2,
    addresses
  })

  return rs
}

export const getMarketDetail = async (asset1: string, asset2: String) => {
  const rs = await rpcRequest('get_market_details', {
    asset1: asset1 === 'tBRT' ? 'XCP' : asset1,
    asset2: asset2 === 'tBRT' ? 'XCP' : asset2,
  })

  return rs
}

export const createOrder = async (address: string, give_quantity: number, asset: string, get_quantity: number, get_asset: string, pubkey: string, fee_per_kb: number) => {
  const rs = await rpcRequest('proxy_to_counterpartyd', {
    method: 'create_order',
    params: {
      "source": address,
      give_quantity,
      give_asset: asset === 'tBRT' ? 'XCP' : asset,
      get_quantity,
      get_asset: get_asset === 'tBRT' ? 'XCP' : get_asset,
      "fee_required": 0,
      "fee_provided": 0,
      "expiration": 8064,
      "extended_tx_info": true,
      "disable_utxo_locks": true,
      "encoding": "auto",
      pubkey,
      "allow_unconfirmed_inputs": true,
      fee_per_kb
    }
  })

  return rs
}


export const payBTC = async (source: string, order_match_id: String) => {
  const rs = await rpcRequest('proxy_to_counterpartyd',
    {
      method: 'create_btcpay',
      params: {
        source,
        order_match_id,
      }
    })

  return rs
}